<template>
  <div class="large-page">
    <breadcrumbs>
      <div class="d-flex flex-grow-1 justify-end align-center">
        <v-btn
          dark
          small
          depressed
          color="red"
          @click="shouldShowDeleteDialog = true"
        >
          <v-icon left>
            delete
          </v-icon>

          Delete
        </v-btn>
      </div>
    </breadcrumbs>

    <user-form
      :key="reloadKey"
      :is-team-view="true"
      :is-loading="isLoading"
      :user-data="userData"
      class="mt-4 mt-md-8 contain-form-width mx-auto"
      @submit="handleSubmit"
    />

    <!-- delete confirm dialog -->
    <v-dialog
      v-model="shouldShowDeleteDialog"
      max-width="300"
    >
      <v-card
        :loading="isLoading"
        :disabled="isLoading"
      >
        <v-card-title>
          Delete this user?
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            depressed
            color="red"
            @click="handleDelete"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const Breadcrumbs = () => import(/* webpackChunkName: "breadcrumbs" */ "@/components/common/Breadcrumbs.vue")
const UserForm = () => import(/* webpackChunkName: "user-form" */ "@/components/common/UserForm.vue")

export default {
  name: "TeamUserEdit",

  components: {
    Breadcrumbs,
    UserForm
  },

  data: () => ({
    userData: null,
    isLoading: false,
    reloadKey: Symbol(),

    shouldShowDeleteDialog: false
  }),

  computed: {
    userId() {
      return this.$route.params.userId
    }
  },

  methods: {
    /**
     * Use the user id in route parameters to fetch the model's serialized data
     */
    async fetchUserData() {
      // fetch the user data for edit form
      this.isLoading = true

      // Create the loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        const response = await axios({
          url: "/api/team/" + this.userId
        })

        this.userData = response.data

        this.reloadKey = Symbol()
      } catch (error) {
        // log using the helper function
        logger({ type: "Network Error", error })
      } finally {
        // close the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    },

    /**
     * Handle the "save" click from the user-form
     *
     * @param {Object} formData
     */
    async handleSubmit(formData) {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: "/api/team/" + this.userId,
          method: "PUT",
          data: {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            password: formData.password,
            is_active: formData.status,
            country: formData.country,
            currency: formData.currency,
            valid_from: formData.valid_from || null,
            valid_to: formData.valid_to || null,
            allowed_services: formData.allowedServices
          }
        })

        // Make transaction related rows
        for (const transaction of Object.values(formData.transactions)) {
          // If the amount is smaller than 0
          if (transaction.amount <= 0) {
            // Skip this one
            continue
          }

          // Make a network request
          await axios({
            url: "/api/team/" + this.userId + "/balance",
            method: "POST",
            data: {
              ...transaction,
              amount: parseFloat(transaction.amount, 10) * constants.conversionRate
            }
          })
        }

        // update the local store
        this.$store.dispatch("team/fetchItems")

        // alert the user
        this.$store.dispatch("toasts/add", {
          text: "User has been updated."
        })

        // update the value for this editable user
        this.fetchUserData()

        // refresh profile data with deducted balance
        window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
          detail: {
            module: "influencerInsight",
            origin: "influencerInsight/fetchProfile"
          }
        }))
      } catch (error) {
        logger({ type: "Team/Edit Error", error })

        this.$store.dispatch("toasts/add", {
          text: error.response.data.message || "An error occurred"
        })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
      }
    },

    /**
     * Handle the "delete" user action
     */
    async handleDelete() {
      this.isLoading = true

      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)

      try {
        await axios({
          url: "/api/team/" + this.userId,
          method: "DELETE"
        })

        // Redirect and let the user know
        this.$store.dispatch("toasts/add", {
          text: "User has been deleted."
        })

        this.$router.replace({
          name: "Team/Index"
        })

        // refresh profile data with deducted balance
        window.dispatchEvent(new CustomEvent('fetchAuthUserProfile', {
          detail: {
            module: "influencerInsight",
            origin: "influencerInsight/fetchProfile"
          }
        }))
      } catch (error) {
        logger({ type: "Team/Delete Error", error })

        // The keys for error messages
        const keys = Object.keys(error.response?.data?.errors || {})

        // If there are any items in this key, read the message
        const errorMessage = keys.length ? error.response.data.errors[keys[0]][0] : (error.response?.data?.message || "An error occurred")

        // Show the error message
        this.$store.dispatch("toasts/add", {
          text: errorMessage
        })
      } finally {
        this.$store.dispatch("loaders/remove", loaderId)
        this.isLoading = false
        this.shouldShowDeleteDialog = false
      }
    },
  },

  mounted() {
    this.fetchUserData()
  }
}
</script>
